/* Bugsnag is a service that your application can send runtime errors to. The bugsnag application will dipslay these errors
   in an overview. This way runtime production error can be tracked and fixed.

   See https://app.bugsnag.com/
*/

import Bugsnag from '@bugsnag/js';

(function() {
   if(window.rails_env === 'production') {
      Bugsnag.start({apiKey: '43bf69b2bc542483d348c8afe9b40bdd' });
   }
})();