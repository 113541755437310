import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
   static targets = ["page", "counter", "previous", "next"];
   static values = {
      index: Number,
   };

   initialize() {
      if (!this.hasIndexValue) {
         this.indexValue = 0;
      }
   }

   next() {
      this.indexValue = Math.min(this.indexValue + 1, this.pageTargets.length);
   }

   previous() {
      this.indexValue = Math.max(this.indexValue - 1, 0);
   }

   indexValueChanged() {
      this.showCurrentPage();
   }

   showCurrentPage() {
      this.pageTargets.forEach((element, index) => {
         element.hidden = index != this.indexValue;
      });

      if (this.pageTargets.length > 1) {
         this.counterTarget.textContent = `(${this.indexValue + 1} of ${this.pageTargets.length})`;

         if (this.hasPreviousTarget) {
            this.previousTarget.disabled = this.indexValue === 0;
         }

         if (this.hasNextTarget) {
            this.nextTarget.disabled = this.indexValue === this.pageTargets.length - 1;
         }
      } else {
         this.counterTarget.hidden = true;

         if (this.hasPreviousTarget) {
            this.previousTarget.hidden = true;
         }

         if (this.hasNextTarget) {
            this.nextTarget.hidden = true;
         }
      }
   }
}
