import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
   static targets = ["reminder", "group"];

   dragReminderStart(event) {
      this.draggedReminderElement = this.get_reminder_element_for(event.target);
      this.sourceGroupElement = this.get_group_element_for(event.target);
      this.targetGroupElement = null;

      event.dataTransfer.effectAllowed = "move";
      event.dataTransfer.setData("text/html", event.target.outerHTML);

      this.sourceGroupElement.classList.add('drag-source');
   }

   dragEnterGroup(event) {
      if (!this.draggedReminderElement) {
         return;
      }

      const newTargetGroupElement = this.get_group_element_for(event.target);

      if (this.sourceGroupElement !== newTargetGroupElement) {
         event.preventDefault();
      }

      if(newTargetGroupElement !== this.targetGroupElement) {
         if(this.targetGroupElement) {
            this.targetGroupElement.classList.remove('drag-target');
         }

         if (this.sourceGroupElement !== newTargetGroupElement) {
            this.targetGroupElement = newTargetGroupElement;
            this.targetGroupElement.classList.add('drag-target');
         }
         else {
            this.targetGroupElement = null;
         }
      }
   }

   dragOverGroup(event) {
      if (!this.draggedReminderElement) {
         return;
      }

      const targetGroupElement = this.get_group_element_for(event.target);
      if (this.sourceGroupElement !== targetGroupElement) {
         event.preventDefault();
      }
   }

   dragLeaveGroup(event) {
      event.preventDefault();
      return false;
   }

   dragEndGroup(event) {
      if(this.sourceGroupElement) {
         this.sourceGroupElement.classList.remove('drag-source');
      }
         
      if(this.targetGroupElement) {
         this.targetGroupElement.classList.remove('drag-target');
      }
      
      this.draggedReminderElement = null;
      this.sourceGroupElement = null;
      this.targetGroupElement = null;

      event.preventDefault();
      return false;
   }

   dropReminder(event) {
      const targetGroupElement = this.get_group_element_for(event.target);
      if (!this.draggedReminderElement || !targetGroupElement) {
         return;
      }

      event.preventDefault();

      const reminderId = this.draggedReminderElement.dataset.reminderId;
      const targetGroupId = targetGroupElement.dataset.groupId;

      fetch(`/reminders/${reminderId}/move/to/group/${targetGroupId}`, {
         method: "PUT",
         credentials: "same-origin",
         headers: {
            "Accept": "text/vnd.turbo-stream.html",
            "Content-Type": "application/json",
            "X-CSRF-Token": document.head.querySelector("[name='csrf-token']").content,
         },
      })
         .then((response) => response.text())
         .then(Turbo.renderStreamMessage);
   }

   get_group_element_for(element) {
      if (this.groupTargets.find((x) => x === element)) {
         return element;
      }

      if (element.parentElement) {
         return this.get_group_element_for(element.parentElement);
      } else {
         return null;
      }
   }

   get_reminder_element_for(element) {
      if (this.reminderTargets.find((x) => x === element)) {
         return element;
      }

      if (element.parentElement) {
         return this.get_reminder_element_for(element.parentElement);
      } else {
         return null;
      }
   }
}
