import { rails_env } from "../helpers/rails_env";

(function () {
   if (rails_env() === "development") {
      console.log("Setting up turbo debug.");

      const events = [
         "turbo:click",
         "turbo:before-visit",
         "turbo:visit",
         "turbo:submit-start",
         "turbo:before-fetch-request",
         "turbo:before-fetch-response",
         "turbo:submit-end",
         "turbo:before-cache",
         "turbo:before-render",
         "turbo:before-stream-render",
         "turbo:render",
         "turbo:load",
         "turbo:frame-render",
         "turbo:frame-load",
         "turbo:fetch-request-error",
      ];

      events.forEach((event) => {
         document.documentElement.addEventListener(event, (...args) => console.log(event, args));
      });
   }
})();
