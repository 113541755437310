import { Controller } from "@hotwired/stimulus";

/*
   Example usage (rails):
   <%= form.text_area :description, "data-controller": "textarea-auto-grow" %>

   Example usage (plain HTML):
   <textarea data-controller="textarea-auto-grow"></textarea>
 */
export default class extends Controller {
   connect() {
      if (this.element.tagName !== "TEXTAREA") {
         console.error("The textarea-auto-grow controller should be added to the textarea element. It is set to: ", this.element.tagName);
         return;
      }

      this.textarea = this.element;
      this.textarea.addEventListener("input", () => this.adjust_size());

      this.adjust_size();
   }

   disconnect() {
      this.textarea.removeEventListener("input", () => this.adjust_size());
   }

   adjust_size() {
      this.textarea.style.height = '0px';
      this.textarea.style.height = `${this.textarea.scrollHeight}px`;
   }
}
