import { Turbo, cable } from "@hotwired/turbo-rails";
window.Turbo = Turbo; //For native adapters.

import * as activeStorage from "@rails/activestorage";
import localTime from "local-time";

// Actiontext
import "trix";
import "@rails/actiontext";

// Channel imports
import "./channels/consumer";

// App imports
import { rails_env } from "./helpers/rails_env";
import "./app/bugsnag-initialize";
import "./app/turbo_debug";

activeStorage.start();
localTime.start();

// Make rails environment available as variable
window.rails_env = rails_env();
console.log(`Running on environment: ${window.rails_env}`);

// Register service worker
if ("serviceWorker" in navigator) {
  console.log("[Service Worker]", "Registering service worker.");
  navigator.serviceWorker.register("/service-worker.js").then(
    (registration) => {
      console.log("[Service Worker]", "Service worker registered!", registration);
    },
    (error) => {
      console.error("[Service Worker]", "Service worker registration failed:", error);
    }
  );
}
else {
  console.log("[Service Worker]", "Service worker not supported.");
}

import { Application } from "@hotwired/stimulus";
const stimulus = Application.start();

// Register app stimulus controllers
import CheckboxShowHideContent from "./controllers/checkbox_show_hide_content_controller";
import Closable from "./controllers/closable_controller";
import Collapsible from "./controllers/collapsible_controller";
import CommonplaceLogo from "./controllers/commonplace_logo_controller";
import ClipboardController from "./controllers/clipboard_controller";
import FormAutoSubmit from "./controllers/form_auto_submit_controller";
import FormCloseSubmit from "./controllers/form_close_submit_controller";
import Paged from "./controllers/paged_controller";
import ReminderDrag from "./controllers/reminder_drag_controller";
import TextareaAutoGrow from "./controllers/textarea_auto_grow_controller";

stimulus.register("checkbox-show-hide-content", CheckboxShowHideContent);
stimulus.register("closable", Closable);
stimulus.register("collapsible", Collapsible);
stimulus.register("commonplace-logo", CommonplaceLogo);
stimulus.register("clipboard", ClipboardController);
stimulus.register("form-auto-submit", FormAutoSubmit);
stimulus.register("form-close-submit", FormCloseSubmit);
stimulus.register("paged", Paged);
stimulus.register("reminder-drag", ReminderDrag);
stimulus.register("textarea-auto-grow", TextareaAutoGrow);

// Register Tailwind stimulus controllers
import { Dropdown, Modal, Tabs, Popover, Toggle } from "tailwindcss-stimulus-components";
stimulus.register("dropdown", Dropdown);
stimulus.register("modal", Modal);
stimulus.register("tabs", Tabs);
stimulus.register("popover", Popover);
stimulus.register("toggle", Toggle);
