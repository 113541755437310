import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
   static targets = ["source", "button"];

   copy() {
      navigator.clipboard.writeText(this.sourceTarget.value);

      const originalTextContent = this.buttonTarget.textContent;
      const originalWidth = this.buttonTarget.offsetWidth;

      this.buttonTarget.style.width = `${originalWidth}px`;
      this.buttonTarget.lastChild.textContent = "Copied!";
      this.buttonTarget.classList.add("bg-green-500");
      this.buttonTarget.classList.add("hover:bg-green-700");
      this.buttonTarget.classList.add("text-white");

      setTimeout(() => {
         this.buttonTarget.lastChild.textContent = originalTextContent;
         this.buttonTarget.classList.remove("bg-green-500");
         this.buttonTarget.classList.remove("hover:bg-green-700");
         this.buttonTarget.classList.remove("text-white");
         this.buttonTarget.style.width = null;
      }, 3000);
   }

   select_all() {
      this.sourceTarget.select();
      this.sourceTarget.setSelectionRange(0, this.sourceTarget.value.length);
   }
}
