import { Controller } from "@hotwired/stimulus";

/*
    Example Usage:
    <div data-controller="collapsible" data-collapsible-initial_state="true">
        <div class="trix-content" data-collapsible-target="content"><%= @book.rich_description %></div>
        <button class="button" data-collapsible-target="more" data-action="click->collapsible#show_more">Show more</button>
        <button class="button" data-collapsible-target="less" data-action="click->collapsible#show_less">Show less</button>
    </div>
*/
export default class extends Controller {
   static targets = ["content", "more", "less"];

   connect() {
      // Check if all elements are present
      if (!this.contentTarget)
         throw '"content" should be available as a data-target, e.g. <div data-target="content"></div>';
      if (!this.moreTarget)
         throw '"more" should be available as a data-target, e.g. <button data-target="more"></button>';
      if (!this.lessTarget)
         throw '"less" should be available as a data-target, e.g. <button data-target="less"></button>';

      //Make sure the read-more component styling is applied.
      this.contentTarget.classList.add("read-more");

      // Determine initial state, only enable when the content is greater than the max height of the element (defaults to 25vh)
      this.enabled = this.contentTarget.scrollHeight > 0.2 * document.documentElement.clientHeight;
      this.isLimited = !!this.data.get("initial_state");
      this.update();

      console.debug(
         `enabled: ${this.enabled} | scrollHeight: ${this.contentTarget.scrollHeight} | clientHeight: ${document.documentElement.clientHeight}`
      );
   }

   show_more() {
      this.isLimited = false;
      this.update();
   }

   show_less() {
      this.isLimited = true;
      this.update();
   }

   update() {
      if (!this.enabled) {
         this.contentTarget.classList.remove("read-more--limited");
         this.contentTarget.classList.remove("read-more--unlimited");

         this.hide(this.moreTarget);
         this.hide(this.lessTarget);
      } else if (this.isLimited) {
         this.contentTarget.classList.add("read-more--limited");
         this.contentTarget.classList.remove("read-more--unlimited");

         this.show(this.moreTarget);
         this.hide(this.lessTarget);
      } else {
         this.contentTarget.classList.remove("read-more--limited");
         this.contentTarget.classList.add("read-more--unlimited");

         this.show(this.lessTarget);
         this.hide(this.moreTarget);
      }
   }

   show(el) {
      el.style.display = "";
   }

   hide(el) {
      el.style.display = "none";
   }
}
