import { Controller } from "@hotwired/stimulus";

/*
    Removes the element from the page when the button is clicked. 

    Example Usage:
    <div data-controller="closable">
        <!-- Content -->
        <button data-action="click->closable#close">Close</button>
    </div>
*/
export default class extends Controller {
   connect() {}

   close() {
      this.removeElement(this.element);
   }

   removeElement(element) {
      element.parentNode.removeChild(element);
   }
}
