import { Controller } from "@hotwired/stimulus";

/*
   Example usage (rails):
   <div data-controller="checkbox-show-hide-content">
    <%= form.check_box :some_thing, data-checkbox-show-hide-content-target="checkbox" %>
    <div data-checkbox-show-hide-content-target="content">
      <!-- some content -->
    </div>
   </div>
 */
export default class extends Controller {
   static targets = ["content", "checkbox"];

   connect() {
      this.checkboxTarget.addEventListener("change", () => this.handleInputEvent());
      this.handleInputEvent();
   }

   disconnect() {}

   handleInputEvent(event) {
      if (this.checkboxTarget.checked) {
         this.show(this.contentTarget);
      } else {
         this.hide(this.contentTarget);
      }
   }

   show(element) {
      element.style.display = "";
   }

   hide(element) {
      element.style.display = "none";
   }
}
