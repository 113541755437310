import { Controller } from "@hotwired/stimulus";

/*
   This controller can be attached to a form. When the user tries to navigate away from a page when changes have been made to the form, a dialog
   will show asking the user if changes should be saved or discarded before navigating to the other page.

   Example usage (rails):
   <%= form_with(url: articles_path, method: :get, html: { 'data-controller': 'form-close-submit' }) do |form| %>
      // From contents
   <% end %>

   Example usage (plain HTML):
   <form data-controller="form-close-submit">
      // Form contents
   </form>
 */

export default class extends Controller {
   connect() {
      if (this.element.tagName !== "FORM") {
         console.error("The form-close-submit controller should be added to the form element. It is set to: ", this.element.tagName);
         return;
      }

      this.form = this.element;
      this.isFormChanged = false;

      this.form.addEventListener("submit", (event) => this.handleFormSubmit(event));

      const inputs = this.getInputs();
      for (let i = 0; i < inputs.length; i++) {
         const input = inputs[i];
         input.addEventListener("change", (event) => this.handleInputEvent(event));
      }

      const trix_editors = this.getTrixEditors();
      for(let i=0; i<trix_editors.length; i++) {
         const trix_editor = trix_editors[i];
         trix_editor.addEventListener("trix-change", (event) => this.handleInputEvent(event));
      }

      window.addEventListener("beforeunload", this.beforeUnloadEventRef = (event) => this.handleBrowserPageChange(event), { capture: true });
      window.addEventListener("turbo:before-visit", this.beforeTurboVisitEventRef = (event) => this.handleTurboPageChange(event));
   }

   disconnect() {
      window.removeEventListener("beforeunload", this.beforeUnloadEventRef);
      window.removeEventListener("turbo:before-visit", this.beforeTurboVisitEventRef);
   }

   handleFormSubmit(event) {
      this.isFormChanged = false;
   }

   handleInputEvent(event) {
      this.isFormChanged = true;
   }

   handleBrowserPageChange(event) {
      if(!this.isFormChanged) {
         return;
      }

      var message = "You have unsaved changes. Do you want to discard them?";
      event.preventDefault();
      event.returnValue = message;
      return message;
   }

   handleTurboPageChange(event) {
      if(!this.isFormChanged) {
         return;
      }

      if(window.confirm("You have unsaved changes. Do you want to save them before leaving?")) {
         event.preventDefault();
         this.form.requestSubmit();
         this.isFormChanged = false;
      }
   }

   getInputs() {
      var inputs = Array.from(this.element.getElementsByTagName("input"));
      var selects = Array.from(this.element.getElementsByTagName("select"));
      return [].concat(inputs, selects);
   }

   getTrixEditors() {
      return Array.from(this.element.getElementsByTagName("trix-editor"));
   }
}
