import { Controller } from "@hotwired/stimulus";

import { debounce } from "../helpers/debounce";
import { debounce_leading } from "../helpers/debounce_leading";

/*
   Example usage (rails):
   <%= form_with(url: articles_path, method: :get, html: { 'data-controller': 'form-auto-submit' }) do |form| %>
      // From contents
   <% end %>

   Example usage (plain HTML):
   <form data-controller="form-auto-submit">
      // Form contents
   </form>
 */
export default class extends Controller {
   static values = { debounce: { type: Number, default: 300 } };

   connect() {
      if (this.element.tagName !== "FORM") {
         console.error("The form-auto-submit controller should be added to the form element. It is set to: ", this.element.tagName);
         return;
      }

      this.form = this.element;

      const inputs = this.getInputs();
      for (let i = 0; i < inputs.length; i++) {
         const input = inputs[i];

         let event = "keyup";
         if (input.type === "checkbox" || input.type === "radio") {
            event = "change";
         }

         input.addEventListener(
            event,
            debounce(() => this.handleInputEvent(), this.debounceValue)
         );
      }
   }

   disconnect() {}

   handleInputEvent(event) {
      // DO NOT USE "this.form.submit();"
      // Turbo listens for the form 'submit' event to handle submitting the form without a page reload. This is not fired by the `submit()` method. See [MDN documentation](https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit)
      // Instead, `requestSubmit()` should be used. See [MDN documentation](https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/requestSubmit)
      this.form.requestSubmit();
   }

   getInputs() {
      return Array.from(this.element.getElementsByTagName("input")).concat(Array.from(this.element.getElementsByTagName("select")));
   }
}
