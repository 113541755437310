import { Controller } from "@hotwired/stimulus";

/*
   Example usage (plain HTML):
   <div data-controller="commonplace-logo"></div>
 */
export default class extends Controller {
   connect() {
      this.element.addEventListener("click", () => this.refresh());
      this.element.addEventListener("touchstart", () => this.refresh());
   }

   disconnect() {}

   refresh() {
      var is_root = window.location.pathname == "/";
      if (is_root) {
         Turbo.clearCache();
         window.location.href = "/";
      }

      Turbo.visit("/");
   }
}
