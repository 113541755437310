// In JavaScript, a debounce function makes sure that your code is only triggered once per user input.
// Search box suggestions, text-field auto-saves, and eliminating double-button clicks are all use cases for debounce.
// This variant fires once after the last input and the timeout has expired.
// Copied from: https://www.freecodecamp.org/news/javascript-debounce-example/
export function debounce(func, timeout = 300) {
   let timer;
   return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
         func.apply(this, args);
      }, timeout);
   };
}
